import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { fetchCompanyTypes, fetchJobRoles } from '../../api/user';

const RegisterationPromotionForm = ({ showRegisterationMessgae, errormsg }) => {
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm();
	const [companyTypes, setCompanyTypes] = useState(null);
	const [jobRoles, setJobRoles] = useState(null);

	const sortjsonArrayById = arr => {
		arr.sort(function (a, b) {
			return a.id - b.id;
		});

		return arr;
	};

	useEffect(() => {
		let companytypes = fetchCompanyTypes();
		let jobroles = fetchJobRoles();

		companytypes
			.then(value => {
				if (value.data.response === 'success') {
					let com_types = sortjsonArrayById(value.data.company_types);
					setCompanyTypes(com_types);
				}
			})
			.catch(error => {
				// console.log(error)
			});

		jobroles
			.then(value => {
				if (value.data.response === 'success') {
					let job_roles = sortjsonArrayById(value.data.job_roles);
					setJobRoles(job_roles);
				}
			})
			.catch(error => {
				//  console.log(error)
			});
	}, []);

	const onSubmit = data => {
		showRegisterationMessgae(data);
	};
	return (
		<div className='login_box'>
			<form className='login_box_inner' onSubmit={handleSubmit(onSubmit)}>
				<div className='text-center'>
					<h3 className='mb-0'>
						Welcome <span>to</span>
					</h3>
					<img src='images/logo.png' className='form_logo_pop' alt='' />
					<h3 className='mb-0'>
						Virtual <span className='experience_col'>Experience</span>
					</h3>
					<div className='wel_form_div'>
						<div className='row'>
							<div className='col-sm-12 col-md-6 col-lg-6'>
								<select
									className='form-control form-control-lg'
									id='exampleFormControlSelect1'
									{...register('company_type_id', { required: true })}>
									<option value=''>COMPANY TYPE</option>
									{companyTypes &&
										companyTypes.map(val => {
											return (
												<option key={val.id} value={val.id}>
													{val.type}
												</option>
											);
										})}
								</select>
								<div className='is-danger'>{errors.company_type_id && <p>* Required</p>}</div>
							</div>
							<div className='col-sm-12 col-md-6 col-lg-6'>
								<select
									className='form-control form-control-lg'
									id='exampleFormControlSelect1'
									{...register('job_role_id', { required: true })}>
									<option value=''>JOB ROLE</option>
									{jobRoles &&
										jobRoles.map(val => {
											return (
												<option key={val.id} value={val.id}>
													{val.role}
												</option>
											);
										})}
								</select>
								<div className='is-danger'>{errors.job_role_id && <p>* Required</p>}</div>
							</div>
							<div className='col-12'>
								<input
									type='text'
									className='form-control form-control-lg'
									placeholder='KEYS 2 SUCCESS PROMO CODE 1 (OPTIONAL)'
									{...register('uto_promo_code1')}
								/>
							</div>
							<div className='col-12'>
								<input
									type='text'
									className='form-control form-control-lg'
									placeholder='KEYS 2 SUCCESS PROMO CODE 2 (OPTIONAL)'
									{...register('uto_promo_code2')}
								/>
							</div>
							<div className='col-12'>
								<input
									type='text'
									className='form-control form-control-lg'
									placeholder='KEYS 2 SUCCESS PROMO CODE 3 (OPTIONAL)'
									{...register('uto_promo_code3')}
								/>
							</div>

							<div className='is-danger col-12 mt-2'>{errormsg}</div>
						</div>
					</div>
				</div>

				<div className='form-group text-center'>
					<button type='submit' className='btn login_btn'>
						Next
					</button>
				</div>
			</form>
		</div>
	);
};

export default RegisterationPromotionForm;

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { fetchState } from '../../api/user';

const RegisterationForm = ({ showRegisterationPromotion }) => {
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm();
	const [otherCountry, setOtherCountry] = useState(false);
	const [states, setStates] = useState([]);

	const onSubmit = data => {
		showRegisterationPromotion(data);
	};

	const handleCountry = e => {
		let val = e.target.value;

		if (val === 'OTHER') {
			setOtherCountry(true);
		} else {
			let states = fetchState(val);

			states
				.then(value => {
					if (value.data.response === 'success') {
						setStates(value.data.states);
						setOtherCountry(false);
					}
				})
				.catch(error => {
					//  console.log(error)
				});
		}
	};

	return (
		<div className='login_box'>
			<form className='login_box_inner' onSubmit={handleSubmit(onSubmit)}>
				<div className='text-center'>
					<h3 className='mb-0'>
						Welcome <span>to</span>
					</h3>
					<img src='images/logo.png' className='form_logo_pop' alt='' />
					<h3 className='mb-0'>
						Virtual <span className='experience_col'>Experience</span>
					</h3>
					<div className='wel_form_div'>
						<div className='row'>
							<div className='col-sm-12 col-md-6 col-lg-6'>
								<input
									type='text'
									className='form-control form-control-lg'
									placeholder='First Name'
									{...register('first_name', { required: true })}
								/>
								<div className='is-danger'>
									{errors.first_name && <p style={{ marginBottom: 0 }}>The first name field is required</p>}
								</div>
							</div>
							<div className='col-sm-12 col-md-6 col-lg-6'>
								<input
									type='text'
									className='form-control form-control-lg'
									placeholder='Last Name'
									{...register('last_name', { required: true })}
								/>
								<div className='is-danger'>
									{errors.last_name && <p style={{ marginBottom: 0 }}>The last name field is required</p>}
								</div>
							</div>
							<div className='col-sm-12 col-md-6 col-lg-6'>
								<input
									type='text'
									className='form-control form-control-lg'
									placeholder='Title'
									{...register('title', { required: true })}
								/>
								<div className='is-danger'>
									{errors.title && <p style={{ marginBottom: 0 }}>The title field is required</p>}
								</div>
							</div>
							<div className='col-sm-12 col-md-6 col-lg-6'>
								<input
									type='text'
									className='form-control form-control-lg'
									placeholder='Company'
									{...register('company', { required: true })}
								/>
								<div className='is-danger'>
									{errors.company && <p style={{ marginBottom: 0 }}>The company field is required</p>}
								</div>
							</div>
							<div className='col-sm-12 col-md-6 col-lg-6'>
								<input
									type='text'
									className='form-control form-control-lg'
									placeholder='City'
									{...register('city', { required: true })}
								/>
								<div className='is-danger'>
									{errors.city && <p style={{ marginBottom: 0 }}>The city field is required</p>}
								</div>
							</div>
							<div className='col-sm-12 col-md-6 col-lg-6'>
								<select
									className='form-control form-control-lg'
									id='exampleFormControlSelect2'
									{...register('country', { required: true })}
									onChange={handleCountry}>
									<option value=''>COUNTRY</option>
									<option value='USA'>USA</option>
									<option value='Canada'>CANADA</option>
									<option value='OTHER'>OTHER</option>
								</select>
								<div className='is-danger'>
									{errors.country && <p style={{ marginBottom: 0 }}>The country field is required</p>}
								</div>
							</div>

							{!otherCountry && (
								<div className='col-sm-12 col-md-6 col-lg-6'>
									<select
										className='form-control form-control-lg'
										id='exampleFormControlSelect1'
										{...register('state', { required: true })}>
										<option value=''>STATE</option>

										{states &&
											states.map(val => {
												return (
													<option key={val} value={val}>
														{val}
													</option>
												);
											})}
									</select>
									<div className='is-danger'>
										{errors.state && <p style={{ marginBottom: 0 }}>The state field is required</p>}
									</div>
								</div>
							)}

							{otherCountry && (
								<>
									<div className='col-12'>
										<input
											type='text'
											className='form-control form-control-lg'
											placeholder='Country'
											{...register('country', { required: true })}
										/>
										<div className='is-danger'>
											{errors.country && (
												<p style={{ marginBottom: 0 }}>The country name field is required</p>
											)}
										</div>
									</div>

									<div className='col-sm-12 col-md-6 col-lg-6'>
										<input
											type='text'
											className='form-control form-control-lg'
											placeholder='State'
											{...register('state', { required: true })}
										/>
										<div className='is-danger'>
											{errors.state && <p style={{ marginBottom: 0 }}>The state field is required</p>}
										</div>
									</div>
								</>
							)}

							<div className='col-sm-12 col-md-6 col-lg-6'>
								<input
									type='text'
									className='form-control form-control-lg'
									placeholder='ZIP Code'
									{...register('zip', { required: true })}
								/>
								<div className='is-danger'>
									{errors.zip && <p style={{ marginBottom: 0 }}>The zip field is required</p>}
								</div>
							</div>

							<div className='col-sm-12 col-md-6 col-lg-6'>
								<select
									className='form-control form-control-lg'
									id='exampleFormControlSelect3'
									{...register('product_used', { required: true })}>
									<option value=''>USED THERMA-TRU PRODUCTS IN PAST 12 MONTHS?</option>
									<option value='Yes'>YES</option>
									<option value='No'>NO</option>
								</select>
								<div className='is-danger'>{errors.product_used && <p>*Required</p>}</div>
							</div>
							<div className='col-sm-12 col-md-6 col-lg-6'>
								<select
									className='form-control form-control-lg'
									id='exampleFormControlSelect4'
									{...register('fypon_used', { required: true })}>
									<option value=''>USED FYPON PRODUCTS IN PAST 12 MONTHS?</option>
									<option value='Yes'>YES</option>
									<option value='No'>NO</option>
								</select>
								<div className='is-danger'>
									{errors.fypon_used && <p style={{ marginBottom: 0 }}>*Required</p>}
								</div>
							</div>
						</div>
					</div>
					<div className='form-group text-center'>
						<button type='submit' className='btn login_btn'>
							Next
						</button>
					</div>
				</div>
			</form>
		</div>
	);
};

export default RegisterationForm;

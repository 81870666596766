import React, { useState } from 'react';
import '../../assets/css/pre_registration_v1.css';
import '../../assets/css/pre_registration_v2.css';
import '../../assets/css/iphone.css';

import $ from 'jquery';
import EmailForm from './EmailForm';
import RegisterationForm from './RegisterationForm';
import RegisterationMessage from './RegisterationMessage';
import RegisterationPromotionForm from './RegisterationPrmotionForm';
import { createUser } from '../../api/user';
import { useEffect } from 'react';
import { useMobileOrientation, isBrowser, isMobile, deviceDetect, isMobileOnly, isTablet, isDesktop } from 'react-device-detect';

var windowWidth = $(window).width();

const Welcome = ({ usersubmitFunc, loadScreen, userSubmited }) => {
	const { isLandscape } = useMobileOrientation();
	const [currentForm, setCurrentForm] = useState(1);
	const [formData, setFormData] = useState(null);
	const [userEmail, setUserEmail] = useState(null);
	// const [wel_width , setWelWIdth] = useState(window.innerWidth)
	// const [wel_height , setWelHeight] = useState(window.innerHeight)
	const [deviceDirection, setDeviceOrientation] = useState(null);
	const [promotionalError, setpromotionalError] = useState(null);

	const [landscapemode, setLandscape] = useState(false);
	// console.log(window.innerWidth, "-----", window.innerHeight);
	useEffect(() => {
		setsize();
		window.addEventListener('resize', setsize);

		return () => {
			window.removeEventListener('resize', setsize);
		};
	}, []);
	// if (isDesktop) {
	// 	console.log('desktop');
	// }

	// if (isTablet) {
	// 	console.log('tablets');
	// }

	// if (isMobileOnly) {
	// 	console.log('mobile');
	// }
	// console.log(isBrowser);
	const setsize = () => {
		//alert(1)
		const wWidth = $(window).width();
		const wHeight = $(window).height();
		if (wHeight > wWidth) {
			setDeviceOrientation('port');
			setLandscape(false);
		} else {
			setDeviceOrientation('land');
			setLandscape(true);
		}
	};

	const showRegisteration = email => {
		setUserEmail(email);
		setCurrentForm(2);
	};

	const showRegisterationPromotion = data => {
		//store registeration data
		setFormData(data);
		setCurrentForm(3);
	};

	const showRegisterationMessgae = data => {
		let requestData = { ...formData, ...data };

		let queryParams = new URLSearchParams(window.location.search);
		if (queryParams.get('email')) {
			requestData.is_fibron_redirected = 1;
		} else {
			requestData.is_fibron_redirected = 0;
		}

		requestData.id = 0;
		requestData.email = userEmail;

		let user = createUser(requestData);
		user.then(value => {
			if (value.data.response === 'success') {
				window.sessionStorage.clear();
				let user = value.data.user.id;
				sessionStorage.setItem('user_id', user);
				usersubmitFunc();
				// setCurrentForm(4)
			}

			if (value.data.response === 'error') {
				setpromotionalError(value.data.message);
			}
		});
		//setCurrentForm(4)
	};
	const welcomeElements = (
		<>
			<div className={`wrapper back-board  ${!userSubmited ? 'active' : ''}`}>
				<div className='login-wrapper'>
					<div className='login-inner'>
						{currentForm === 1 && (
							<EmailForm
								usersubmitFunc={usersubmitFunc}
								loadScreen={loadScreen}
								showRegisteration={showRegisteration}
								changeresize={setsize}
							/>
						)}
						{currentForm === 2 && <RegisterationForm showRegisterationPromotion={showRegisterationPromotion} />}

						{currentForm === 3 && (
							<RegisterationPromotionForm
								showRegisterationMessgae={showRegisterationMessgae}
								errormsg={promotionalError}
							/>
						)}

						{currentForm === 4 && <RegisterationMessage />}
					</div>
				</div>
				<div className='canvas_div'>
					<img
						src='images/home_view.png'
						alt=''
						className='w-100'
						onLoad={() => {
							loadScreen(false);
						}}
					/>
				</div>
			</div>

			<div className={`back-board flex-part loading`}>
				<div className='loading-circle'></div>
			</div>
		</>
	);

	return (
		<>
			{isMobileOnly && landscapemode && (
				<div className={`back-board mobile_rotate active`}>
					<div className='mobile_rotate_inner_box'>
						<h2>
							<span>Welcome</span> To
						</h2>
						<img src='images/logo.png' alt='' className='mobile_logo' style={{ width: '200px' }} />
						<img src='images/rotate.svg' alt='' className='rotate img-fluid mb-3 mt-3' style={{ width: '40px' }} />
						<p>Please rotate your device into Portrait mode to login into 2023 Therma-Tru Virtual Experience</p>
					</div>
				</div>
			)}
			{!landscapemode && isMobileOnly && <> {welcomeElements} </>}
			{(isDesktop || isTablet) && <> {welcomeElements} </>}
			{isBrowser && <> {welcomeElements} </>}
		</>
	);
};

export default Welcome;

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import React, { Suspense } from 'react';
import { useEffect } from 'react';
import Welcome from './components/Welcome';
import $ from 'jquery';

//Page refresh if user inactive for 4 hours long
var time = new Date();
$(document.body).bind('mousemove keypress', function (e) {
	time = new Date();
});

function refresh() {
	let timeDiff = new Date() - time; //in ms
	timeDiff /= 1000;
	let seconds = Math.round(timeDiff);

	if (seconds > 10800) {
		window.location.reload(true);
	} else {
		setTimeout(refresh, 3600000);
	}
}
setTimeout(refresh, 3600000);

// import DirectionRotation from "./components/Util/DirectionRotation";

const MainComponent = React.lazy(() => import('./components/Main'));

function App() {
	const [userSubmited, setUserSubmited] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [showMainComponent, setShowMainComponent] = React.useState(false);

	useEffect(() => {
		if (userSubmited) {
			setShowMainComponent(true);
		}
	}, [userSubmited]);

	const handleUserSubmit = () => {
		setUserSubmited(true);
	};

	const handleLoadScreen = status => {
		setLoading(status);
	};

	return (
		<>
			{!showMainComponent && (
				<>
					{/* <DirectionRotation deviceDirection={this.state.dir} tSize={tSize} />  */}
					<Welcome userSubmited={userSubmited} usersubmitFunc={handleUserSubmit} loadScreen={handleLoadScreen} />
				</>
			)}
			<Suspense fallback={<div></div>}>
				<MainComponent userSubmited={showMainComponent} />
			</Suspense>
		</>
	);
}

export default App;
